import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

type Space = GatsbyTypes.AllSpacesQuery['spaces']['edges'][0];

const useSpaces = () => {
	const { spaces } = useStaticQuery<GatsbyTypes.AllSpacesQuery>(graphql`
		query AllSpaces {
			spaces: allWordpressWpSpace {
				edges {
					node {
						title
						wordpress_id
						slug
						acf {
							meta_title
							meta_description
							header {
								header_logo {
									source_url
									alt_text
								}
								header_title
								header_link
								header_link_text
								header_link_popup
								header_images {
									image {
										source_url
										alt_text
									}
									focus
								}
								corner_image {
									source_url
									alt_text
								}
							}
							circular_logo {
								source_url
								alt_text
							}
							features {
								features {
									title
									link
									icon
								}
							}
							overview_title
							overview_text
							overview_image {
								source_url
								alt_text
							}
							testimonial {
								testimonial_testimonial
								testimonial_community_member
								testimonial_name
								testimonial_company
								testimonial_image {
									source_url
									alt_text
								}
							}
							title
							subtitle
							map_image {
								source_url
							}
							coming_soon
							address
							telephone
							email
							team {
								title
								text
								team {
									wordpress_id
								}
							}
							membership_block_title
							membership_block_text
							membership_block_link
							membership_block_link_text
							membership_types {
								title
								price
								plus_vat
								per
								features
							}
							text_sections {
								title
								text
								image {
									source_url
									alt_text
								}
							}
							membership_link_popup
							membership_link
							membership_link_text
							cta {
								call_hide
								call_title
								call_text
								call_link
								call_link_text
								calendly_link
							}
						}
					}
				}
			}
		}
	`);

	const findOne = ({
		slug,
		wordpress_id,
	}: {
		slug?: string;
		wordpress_id?: number;
	}): Space | undefined => {
		return spaces.edges.find(({ node }) => {
			if (slug) return node.slug === slug;
			if (wordpress_id) return node.wordpress_id === wordpress_id;
			return null;
		});
	};

	return {
		allSpaces: spaces.edges,
		findOne,
	} as const;
};

export default useSpaces;
