import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import {
	Context,
	ContactCard,
	CentralTextBlock,
	Hero,
	MetaData,
	TitleSection,
} from '../components';
import Button from '../components/Button';
import useSpaces from '../utils/hooks/useSpaces';
import breakpoints from '../styles/breakpoints';

const Contact = ({ pageContext: { title, acf, options } }) => {
	const { findOne } = useSpaces();

	const spaces = acf?.spaces_spaces?.map((item) =>
		findOne({ wordpress_id: item.wordpress_id })
	);

	return (
		<Context.Consumer>
			{({ setModal }) => {
				return (
					<Fragment>
						<MetaData
							title={acf.meta_title || title}
							desciption={acf.meta_description}
						/>

						<Hero
							images={acf.contact_header_images}
							title={acf.contact_header_title}
							logo={
								acf.contact_header_logo && acf.contact_header_logo.source_url
							}
							link={acf.contact_header_link}
							linkText={acf.contact_header_linkText}
							linkPopup={acf.contact_header_linkPopup}
						/>

						<CentralTextBlock
							title={acf.grey_box.title}
							text={acf.grey_box.text}
							linkPopup={acf.grey_box.link_popup}
							link={acf.grey_box.link}
							linkText={acf.grey_box.link_text}
							after={
								<>
									<SocialsRow className="contact-icon-row w-row">
										{options.options.social_media.map(({ type, url }) => {
											// const Icon = socialIcons[type];
											return (
												<div>
													<ContactCircle
														href={url}
														className="contact-link-block w-inline-block"
														target="_blank"
													>
														<span className="sr-only">{type}</span>
														{type === 'Twitter' && (
															<svg
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 512 512"
															>
																<path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
															</svg>
														)}
														{type === 'Facebook' && (
															<svg
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 264 512"
															>
																<path d="M215.8 85H264V3.6C255.7 2.5 227.1 0 193.8 0 124.3 0 76.7 42.4 76.7 120.3V192H0v91h76.7v229h94V283h73.6l11.7-91h-85.3v-62.7c0-26.3 7.3-44.3 45.1-44.3z" />
															</svg>
														)}
														{type === 'Instagram' && (
															<svg
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 448 512"
															>
																<path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
															</svg>
														)}
														{type === 'LinkedIn' && (
															<svg
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 448 512"
															>
																<path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
															</svg>
														)}
														{type === 'Pinterest' && (
															<svg
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 448 512"
															>
																<path d="M448 80v352c0 26.5-21.5 48-48 48H154.4c9.8-16.4 22.4-40 27.4-59.3 3-11.5 15.3-58.4 15.3-58.4 8 15.3 31.4 28.2 56.3 28.2 74.1 0 127.4-68.1 127.4-152.7 0-81.1-66.2-141.8-151.4-141.8-106 0-162.2 71.1-162.2 148.6 0 36 19.2 80.8 49.8 95.1 4.7 2.2 7.1 1.2 8.2-3.3.8-3.4 5-20.1 6.8-27.8.6-2.5.3-4.6-1.7-7-10.1-12.3-18.3-34.9-18.3-56 0-54.2 41-106.6 110.9-106.6 60.3 0 102.6 41.1 102.6 99.9 0 66.4-33.5 112.4-77.2 112.4-24.1 0-42.1-19.9-36.4-44.4 6.9-29.2 20.3-60.7 20.3-81.8 0-53-75.5-45.7-75.5 25 0 21.7 7.3 36.5 7.3 36.5-31.4 132.8-36.1 134.5-29.6 192.6l2.2.8H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48z" />
															</svg>
														)}
														{type === 'YouTube' && (
															<svg
																xmlns="http://www.w3.org/2000/svg"
																viewBox="0 0 576 512"
															>
																<path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" />
															</svg>
														)}
													</ContactCircle>
												</div>
											);
										})}
									</SocialsRow>
									<SocialsRow className="contact-icon-row w-row">
										{options.options.telephone && (
											<div>
												<ContactCircle
													href={`tel:${options.options.telephone.replace(
														/ /g,
														''
													)}`}
													className="contact-link-block w-inline-block"
												>
													<span className="sr-only">Phone us</span>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 512 512"
													>
														<path d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z" />
													</svg>
												</ContactCircle>
											</div>
										)}

										{options.options.email && (
											<div>
												<ContactCircle
													href={`mailto:${options.options.email}`}
													className="contact-link-block w-inline-block"
												>
													<span className="sr-only">Email us</span>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 512 512"
													>
														<path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z" />
													</svg>
												</ContactCircle>
											</div>
										)}
									</SocialsRow>
								</>
							}
						/>

						{!!spaces && !!spaces.length && (
							<TitleSection
								title={acf.spaces_title}
								content={
									<>
										{acf.spaces_text && (
											<SpacesText>{acf.spaces_text}</SpacesText>
										)}
										<SpaceCards className="w-row">
											{spaces.filter(Boolean).map(({ node: { title, acf } }) => (
												<SpaceCard>
													<ContactCard
														title={title}
														subtitle={acf.subtitle}
														address={acf.address}
														phone={acf.telephone}
														email={acf.email}
													/>
												</SpaceCard>
											))}
										</SpaceCards>
									</>
								}
							/>
						)}

						<TitleSection
							title={acf.head_office_title}
							color={'grey'}
							content={
								<Fragment>
									{acf.head_office_text ? (
										<div
											className="paragraph-2"
											dangerouslySetInnerHTML={{
												__html: acf.head_office_text,
											}}
										/>
									) : null}

									<p className="head-office-address">
										{options.options.telephone ? (
											<a
												href={
													'tel:' + options.options.telephone.replace(/ /g, '')
												}
											>
												<span className="icomoon icon-span-pink icomoon icon-telephone" />
												<br />
												{options.options.telephone}
												<br />‍<br />
											</a>
										) : null}

										{options.options.telephone ? (
											<a
												href={
													'mailto:' + options.options.email.replace(/ /g, '')
												}
											>
												<span className="icomoon icon-span-pink icomoon icon-mailbox-full" />
												<br />
												{options.options.email}
												<br />‍<br />
											</a>
										) : null}
									</p>

									{acf.head_office_link_text ? (
										<div className="button-wrapper-bottom">
											{acf.head_office_link_popup ? (
												<Button
													onClick={() => setModal(true)}
													style={{
														marginRight: 'auto',
														marginLeft: 'auto',
													}}
												>
													{acf.head_office_link_text}
												</Button>
											) : (
												<Button
													href={acf.head_office_link}
													style={{
														marginRight: 'auto',
														marginLeft: 'auto',
													}}
												>
													{acf.head_office_link_text}
												</Button>
											)}
										</div>
									) : null}
								</Fragment>
							}
						/>
					</Fragment>
				);
			}}
		</Context.Consumer>
	);
};

// const socialIcons = {
//   Facebook,
//   Instagram,
//   LinkedIn,
//   Pinterest,
//   Twitter,
//   YouTube,
// }

const SpacesText = styled.p`
	max-width: 640px;
	margin: -1.5rem auto 2rem;
	text-align: center;
`;

const ContactCircle = styled.a`
	position: relative;
	display: grid;
	place-items: center;
	place-content: center;
	width: 40px;
	height: 40px;
	background: rgb(244, 244, 244);
	border-radius: 50%;

	svg {
		position: absolute;
		max-width: 24px;
		max-height: 24px;
		fill: #525252;
	}
`;

const SocialsRow = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;

	> * {
		padding: 10px;
	}

	&:not(:last-child) {
		margin: 0;
	}
`;

const SpaceCards = styled.div`
	--spacing: 14px;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin: calc(var(--spacing) * -1);
`;

const SpaceCard = styled.div`
	display: flex;
	width: calc((100% - (var(--spacing) * 6)) / 3);
	margin: var(--spacing);

	> div {
		width: 100%;
	}

	@media ${breakpoints.tablet} {
		width: calc((100% - (var(--spacing) * 4)) / 2);
	}

	@media ${breakpoints.mobilePort} {
		width: 100%;
	}
`;

export default Contact;
